import React from "react";

import NavMenu from "../NavMenu";
import Footer from "../Footer";
import SectionOrcamento from "../SectionOrcamento";
import Img from "./Img";

import aneel from "../../images/aneel.jpg";
import tuv from "../../images/tuv.jpeg";
import ul from "../../images/ul.jpg";
import inmetro from "../../images/inmetro.jpg";

import { Container } from "./styles";

const QuemSomosPage = () => {
  return (
    <>
      <NavMenu />
      <Container>
        <h1>
          A Empresa <strong>SF Solar</strong>
        </h1>
        <hr className="hr" />

        <p>
          Localizada em Campo Grande-MS, a <strong>SF Solar</strong> é uma
          empresa voltada a redução de custos pagos pelo consumo de energia,
          através da implantação de sistemas fotovoltaicos que possibilitam a
          geração da própria energia utilizando fontes limpas e renováveis.{" "}
        </p>
        <br />
        <p>
          Fornecemos soluções completas de energia solar para residências,
          empresas, comércio e áreas rurais utilizando tecnologia de ponta.{" "}
        </p>
        <br />
        <p>
          Contamos com uma equipe técnica altamente qualificada, compostas por
          engenheiros elétricos e ambientais, além de mão de obra especializada
          e certificada conforme determinado pelas normas NR10, NR12 e NR35.
        </p>
        <div>
          <Img />
        </div>
        <article>
          <p>
            Todo o nosso sistema está regulamentado pelas normativas previstas
            nas resoluções 482/12 e 687/15 (ANEEL). Utilizamos{" "}
            <b>placas solares</b> que possuem até <b>25 anos de garantia</b> do
            fabricante e <b>classificação A</b> do INMETRO em eficiência
            energética. Além de trabalhamos com produtos que têm certificações
            internacionais como: TUV, UL IEC e VDE.
          </p>
          <br />
          <div>
            <img src={aneel} alt="Aneel" />

            <img src={tuv} alt="Tuv" />
            <img src={ul} alt="UL" />

            <img src={inmetro} alt="Inmetro" />
          </div>
        </article>

        <SectionOrcamento />
      </Container>
      <Footer />
    </>
  );
};

export default QuemSomosPage;
