import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 72px;

  div {
    max-height: 650px;
    margin-bottom: 100px;
  }

  h1 {
    font-size: 46px;
    font-weight: bold;
    text-align: center;
    padding: 0 32px;

    @media (max-width: 885px) {
      font-size: 36px;
    }

    @media (max-width: 515px) {
      font-size: 24px;
      padding: 0 10px;
    }
  }

  .hr {
    margin: 0 auto;
    margin-top: 24px;
    width: 140px;
    height: 3px;
    background: #e66000;
    border: 0;
  }

  strong {
    font-family: "Widges", sans-serif;
  }

  p {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    color: #8a8a8a;
    font-size: 18px;
    line-height: 28px;
    background: #fff;
    padding: 0 32px;

    @media (max-width: 450px) {
      font-size: 16px;
    }

    :first-of-type {
      margin-top: 64px;
    }

    :last-of-type {
      margin-bottom: 64px;
    }
  }

  article {
    div {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      @media (max-width: 1160px) {
        flex-direction: column;
      }

      img:nth-of-type(1) {
        max-width: 330px;
        padding: 20px;
      }

      img:nth-of-type(2) {
        max-width: 150px;
        padding: 20px 0;
      }

      img:nth-of-type(3) {
        max-width: 140px;
        padding: 20px 0;
      }

      img:nth-of-type(4) {
        max-width: 160px;
        padding: 20px 0;
        margin-bottom: 20px;
      }
    }
  }
`;
