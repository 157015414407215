import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";

const Painel = () => {
  const img = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "house.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxHeight: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return <Img fluid={img.file.childImageSharp.fluid} alt="família Solar" />;
};

const StyledImg = styled(Painel)`
  background-position: center bottom;
`;

export default StyledImg;
